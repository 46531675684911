import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AreaInput from './AreaInput';
import TestSelect from './TestSelect';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import DisplayAhorro from './DisplayAhorro';
import DisplayRetorno from './DisplayRetorno';
import DisplayCalcu from './DisplayCalcu';
import DisplayEdif from './DisplayEdif';
import DisplayCondition from './DisplayCondition';
import DisplaySaving from './DisplaySaving';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';
import DisplayTarifa from './DisplayTarifa';

//Primer valor para ubicar tecnologia, segundo valor EUI base, tercer valor factor emisiones
const residencial = [
  { "Departamento": [0, 316.89, 0.00058979379]}
]
  
//Primer valor para ubicar tecnologia, segundo valor EUI base, tercer valor factor emisiones
const noresidencial = [
  { "Restaurante": [1, 372.333977, 0.0006005079144]},
  { "Aulas": [2, 314.774084,0.00057703888]},
  { "Oficina": [3, 292.968429, 0.00057703888]},
  { "Hospital": [4, 882.23513, 0.0005887733972]}
  ]

//[1]: departamento [2]: restaurante [3]: aulas [4]: oficina [5]: hospital
/*const tecnologias = [
  {"PCM": [128.401676125, 273.662720607143, 194.604431875, 139.794239785714, 375.327964107143]},
  {"PCM + Pintura Reflectiva": [118.344585839286, 242.4896195, 169.281653875, 117.708888553571, 328.334337625]},
  {"PCM + Aislante": [118.888194053571, 257.602488910714, 184.651429357143, 134.1043315, 361.580940857143]}
]*/

const euis = [
  {"C40": [58.57, 545.93, 414.39, 374.87, 1091.83]},
  {"C100": [59.47, 591.26, 453.33, 411.23, 1141.56]},
  {"TB40": [58.23, 537.63, 409.25, 372.34, 1081.97]},
  {"TB100": [58.70, 571.04, 440.42, 398.73, 1118.90]},
  {"TA40": [58.09, 534.06, 406.94, 368.41, 1077.62]},
  {"TA100": [58.33, 562.30, 434.58, 293.24, 1108.80]},
  {"R40": [58.22, 543.10, 404.96, 367.72, 1078.26]},
  {"R100": [58.73, 564.36, 432.95, 392.83, 1112.03]},
  {"D40": [57.88, 523.00, 393.95, 359.89, 1061.96]},
  {"D100": [58.04, 537.81, 406.56, 372.59, 1075.84]}
]

//[1]: departamento [2]: restaurante [3]: aulas [4]: oficina [5]: hospital
const ahorros = [
  {"C40": [0.0, 0.0, 0.0, 0.0, 0.0]},
  {"C100": [0.0, 0.0, 0.0, 0.0, 0.0]},
  {"TB40": [0.34, 8.30, 5.14, 2.53, 9.86]},
  {"TB100": [0.77, 20.22, 12.91, 12.50, 22.66]},
  {"TA40": [0.48, 11.87, 7.45, 6.46, 14.21]},
  {"TA100": [1.14, 28.96, 18.75, 117.99, 32.76]},
  {"R40": [0.35, 2.83, 9.43, 7.15, 13.57]},
  {"R100": [0.74, 26.90, 20.38, 18.40, 29.53]},
  {"D40": [0.69, 22.93, 20.44, 14.98, 29.87]},
  {"D100": [1.43, 53.45, 46.77, 38.64, 65.72]}
]

const costos = [
  {"C": [527.80]},
  {"TB": [757.25]},
  {"TA": [934.96]},
  {"R": [904.80]},
  {"D": [3136.64]},
]

const ventanas = [
  {"Tintado Bronce": [2, 2, 2, 2, 2]},
  {"Tintado Azul": [3, 3, 3, 3, 3]},
  {"Reflectivo": [5, 5, 5, 5, 5]},
  {"Doble Low E": [7, 7, 7, 7, 7]}
]

const porcentajes = [
  {"40%": [11, 11, 11, 11, 11]},
  {"100%": [13, 13, 13, 13, 13]}
]

const tarifasResi = [
  {"1C": [1.58]},
  {"DAC": [4.80]}
]

const tarifasNoResi = [
  {"PDBT": [3.18]},
  {"GDMTH": [1.23]}
]

const useStyles = makeStyles(theme => ({
  results: {
    marginTop: 10
  },
  firstSection: {
    backgroundColor: '#f5f5f5'
  },
  placeholder: {
    backgroundColor: '#f5f5f5',
    width: "100%",
    height: "100%"
  },
  backPaper: {
    marginTop: '30px',
    marginBottom: "50px"
  },
  electricBack: {
    backgroundColor: "#004783",
    width: "100%",
    height: "100%"
  },
  electricR: {
    color: "white"
  },
  heatBack: {
    backgroundColor: "#FF0000",
    width: "100%",
    height: "100%"
  },
  heatR: {
    color: "white"
  },
  helpImg: {
    width: "116px",
    height: "60px"
  }
}));



const Edificacion = ( {edif} ) => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(null);
  const [selectedAux, setSelectedAux] = React.useState(null);
  const [selectedBase, setSelectedBase] = React.useState(null);
  const [selectedFactor, setSelectedFactor] = React.useState(null);
  const [selectedTech, setSelectedTech] = React.useState(null);
  const [selectedVentana, setSelectedVentana] = React.useState(null);
  const [selectedPorcentaje, setSelectedPorcentaje] = React.useState(null);
  const [area, setArea] = React.useState(0);

  const [resultBase, setResultBase] = React.useState('');
  const [resultProy, setResultProy] = React.useState('');

  const [geiB, setGeiB] = React.useState('');
  const [geiP, setGeiP] = React.useState('');
  
  const [ahorro, setAhorro] = React.useState(0);
  const [insight, setInsight] = React.useState(null);
  const [tree, setTree] = React.useState(null);

  const [costoSin, setCostoSin] = React.useState(0);
  const [costoCon, setCostoCon] = React.useState(0);
  const [tarifa, setTarifa] = React.useState(0);
  const [roi, setRoi] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation();

  //const tec = t('tec');
  const tipoEdif = t('tipo edif')
  const vent = t('vent');
  const relacion = t('relacion');
  const are = t('area const');
  const meas = t('khora');
  const meas2 = t('taño');
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOption = (optionValue) => {
    setSelected(optionValue)
  }

  const handleArea = (areaValue) =>{
    setArea(areaValue);
  }

  const handleEdifType = (edifValue) =>{
    if (edifValue !== ''){
      let arr = edifValue.split(",");
      setSelectedAux(parseInt(arr[0]));
      setSelectedBase(parseFloat(arr[1]));
      setSelectedFactor(parseFloat(arr[2]));
    }
  }

  const handleTarifa = (edifValue) =>{
    if (edifValue !== ''){
      setTarifa(edifValue);
    }
  }

  /*const handleTech = (optionsArray) =>{
    let arr = optionsArray.split(",");
    setSelectedTech(arr[selectedAux]);
  }*/

  const handleVentana = (optionsArray) => {
    let arr = optionsArray.split(",");
    setSelectedVentana(arr[selectedAux]);
  }

  const handlePorcentaje = (optionsArray) => {
    let arr = optionsArray.split(",");
    setSelectedPorcentaje(arr[selectedAux]);
  }

  useEffect( () =>{
    const calculateResults = () => {
      let calcBase;
      if (selectedPorcentaje == 11) 
        calcBase = area * euis[0]['C40'][selectedAux];
      else
        calcBase = area * euis[1]['C100'][selectedAux];
      setResultBase(parseFloat(calcBase.toFixed(2)));
      let calcProy = area * selectedTech;
      setResultProy(parseFloat(calcProy.toFixed(2)));
      let saving = 100 - (calcProy*100/calcBase);
      setAhorro(parseFloat(saving.toFixed(2))); 
      let calcuIns = (calcBase - calcProy) / 4116.4;
      setInsight(parseFloat(Math.round(calcuIns)));
      let calcuTree = (calcBase - calcProy)*3.64/10000;
      setTree(parseFloat(Math.round(calcuTree)));
    }

    let caseNum = selectedVentana * selectedPorcentaje;
    let aux;
    let aux2 = area / area;

    switch (caseNum) {
      case 22:
        setSelectedTech(euis[2]['TB40'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[1]['TB'] * aux2);
        aux = ahorros[2]['TB40'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 26:
        setSelectedTech(euis[3]['TB100'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[1]['TB'] * aux2);
        aux = ahorros[3]['TB100'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 33:
        setSelectedTech(euis[4]['TA40'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[2]['TA'] * aux2);
        aux = ahorros[4]['TA40'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 39:
        setSelectedTech(euis[5]['TA100'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[2]['TA'] * aux2);
        aux = ahorros[5]['TA100'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 55:
        setSelectedTech(euis[6]['R40'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[3]['R'] * aux2);
        aux = ahorros[6]['R40'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 65:
        setSelectedTech(euis[7]['R100'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[3]['R'] * aux2);
        aux = ahorros[7]['R100'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 77:
        setSelectedTech(euis[8]['D40'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[4]['D'] * aux2);
        aux = ahorros[8]['D40'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      case 91:
        setSelectedTech(euis[9]['D100'][selectedAux]);
        setCostoSin(costos[0]['C'] * aux2);
        setCostoCon(costos[4]['D'] * aux2);
        aux = ahorros[9]['D100'][selectedAux];
        setRoi(Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05)));
        break;
      default:
        break;
    }

    calculateResults();
  }, [selectedBase, selectedTech, selectedVentana, selectedPorcentaje, selectedAux, area, costoSin, costoCon, tarifa]);

  useEffect( () =>{
    const calculateGei = () => {
      let calcGeiB = selectedFactor * resultBase;
      setGeiB(parseFloat(calcGeiB.toFixed(2)));
      let calcGeiP = selectedFactor * resultProy;
      setGeiP(parseFloat(calcGeiP.toFixed(2)));
    }
    calculateGei();
  }, [resultBase, resultProy, selectedFactor]);

  

  function reset() {
    setSelected(null);
    setArea(0);
    setSelectedBase('');

    setResultBase('');
    setResultProy('');

    setGeiB('');
    setGeiP('');

    setAhorro(0);
    setInsight(null);
    setTree(null);
  }
  
  return(
    <div>
      <Paper elevation={1} className={classes.backPaper}>
        <Box p={2}>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" className={classes.header}>
                {t('atv')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={reset}>{t('limp')}</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <TestSelect options={edif} label={tipoEdif} selected={selected} onSelectedChange={handleOption}/>
                <DisplayEdif selectedEdif={selected} onEdifChange={handleEdifType} resi={residencial} noresi={noresidencial}/>
                <DisplayTarifa selectedEdif={selected} onTarifaChange={handleTarifa} resi={tarifasResi} noresi={tarifasNoResi} />
                <DisplayCondition options={ventanas} label={vent} param={selectedBase} onChange={handleVentana} info="ventanas" />
                <DisplayCondition options={porcentajes} label={relacion} param={selectedBase} onChange={handlePorcentaje} info="relacion" />
                <AreaInput onValueChange={handleArea} areaValue={area} idInput="input1" idHelper="input1-helper" label={are} />
              </Box>
              <Button aria-describedby={id} onClick={handleClick}>
                <InfoIcon  color="primary"/>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
              >
                <Box p={2}>
                  {t('cons ed 1')}
                  <ul>
                    <li>{t('cons ed 11')}</li>
                    <li>{t('cons ed 12')}</li>
                    <ul>
                      <li>{t('cons ed 13')}</li>
                        <ul>
                          <li>{t('cons ed 14')}</li>
                          <li>{t('cons ed 15')}</li>
                          <li>{t('cons ed 16')}</li>
                          <li>{t('cons ed 17')}</li>
                        </ul>
                      <li>{t('cons ed 18')}</li>
                      <li>{t('cons ed 19')}</li>
                    </ul>
                    <li>{t('cons ed 20')}</li>
                  </ul>
                </Box>
              </Popover>
              <Box display="flex" fontSize={16}>
                {t('calc mty mex')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ceg')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('sin tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('con tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="blue" 
                    units={meas} 
                    result={resultBase}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="blue" 
                    units={meas} 
                    result={resultProy}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ega')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('sin tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('con tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units={meas2} 
                    result={geiB}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units={meas2} 
                    result={geiP}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ana econ')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('costo ad sin')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('costo ad con')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="yellow" 
                    units="MXN/m²" 
                    result={costoSin}
                    className={classes.money}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="yellow" 
                    units="MXN/m²"
                    result={costoCon}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayAhorro ahorro={ahorro}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayRetorno roi={roi}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box textAlign="center" fontSize={20} fontStyle="medium">
                {t('res sol 3')}
              </Box>
              <DisplaySaving insight={insight} toggle={true} />
              <DisplaySaving insight={tree} toggle={false}/>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default Edificacion;