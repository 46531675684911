import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import MemberView from './MemberView';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  photos: {
    height: '120px',
    width: '120px'
  },
  footer:{
    backgroundColor: "#e7ebf6",
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '50px'
  },
}));

const TeamComponent = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const carlos = t('carlos');
  const gijon = t('gijon');
  const ivan = t('ivan');
  const caribay = t('caribay');
  const jorge = t('jorge');
  const niloufar = t('niloufar');
  const santiago = t('santiago');
  const diana = t('diana');
  const luis = t('luis');
  const juande = t('juande');
  const ivann = t('ivann');
  const daniel = t('daniel');
  const zyanya = t('zyanya');

  return(
    <div>
      <Container>
        <Box display="flex" justifyContent="center" m={1} fontFamily="Tenor Sans" fontWeight="bold" fontSize={30} marginTop="30px">
          {t('et')}
        </Box>   
        <Box fontSize={20} fontFamily='Tenor Sans' textAlign="left" p={3}>
          {t('parr equip')}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs ={12} sm={6}>
            <MemberView imgSrc="carlos.png" nombre="Dr. Carlos Iván Rivera Solorio" puesto={carlos} size="30" desc="subtitle1"></MemberView>
          </Grid>
          <Grid item xs ={12} sm={6}>
            <MemberView imgSrc="gijon.png" nombre="Dr. Miguel Ángel Gijón Rivera" puesto={gijon} size="30" desc="subtitle1"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="ivan.png" nombre="Ph.D(c) Iván Acosta Pazmiño" puesto={ivan} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="caribay.png" nombre="Ph.D(c) Caribay Godoy Rangel" puesto={caribay} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="jorge.png" nombre="Msc. Jorge Alfonso Tirado" puesto={jorge} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="niloufar.png" nombre="Arq. Niloufar Mousavi" puesto={niloufar} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="santiago.png" nombre="Santiago Romo Gallego" puesto={santiago} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="diana.png" nombre="Diana Rosete Taboada" puesto={diana} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="luis.png" nombre="Luis Almanza Vazquez" puesto={luis} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="juande.png" nombre="Juan de Dios Martínez" puesto={juande} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="ivann.png" nombre="Iván Contreras Rodríguez" puesto={ivann} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="daniel.png" nombre="Daniel Alejandro David Sánchez" puesto={daniel} size="24" desc="subtitle2"></MemberView>
          </Grid>
          <Grid item xs={12} sm= {4}>
            <MemberView imgSrc="zyanya.png" nombre="Zyanya Verónica Castro Belmont" puesto={zyanya} size="24" desc="subtitle2"></MemberView>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TeamComponent;