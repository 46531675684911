import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AreaInput from './AreaInput';
import TestSelect from './TestSelect';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import DisplayAhorro from './DisplayAhorro';
import DisplayRetorno from './DisplayRetorno';
import DisplayCalcu from './DisplayCalcu';
import DisplayEdif from './DisplayEdif';
import DisplayCondition from './DisplayCondition';
import DisplaySaving from './DisplaySaving';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';
import DisplayTarifa from './DisplayTarifa';

//Primer valor para ubicar tecnologia, segundo valor EUI base, tercer valor factor emisiones
const residencial = [
  { "Departamento": [0, 316.89, 0.00058979379]}
]
  
//Primer valor para ubicar tecnologia, segundo valor EUI base, tercer valor factor emisiones
const noresidencial = [
  { "Restaurante": [1, 372.333977, 0.0006005079144]},
  { "Aulas": [2, 314.774084,0.00057703888]},
  { "Oficina": [3, 292.968429, 0.00057703888]},
  { "Hospital": [4, 882.23513, 0.0005887733972]}
  ]

//[1]: departamento [2]: restaurante [3]: aulas [4]: oficina [5]: hospital
/*const tecnologias = [
  {"PCM": [128.401676125, 273.662720607143, 194.604431875, 139.794239785714, 375.327964107143]},
  {"PCM + Pintura Reflectiva": [118.344585839286, 242.4896195, 169.281653875, 117.708888553571, 328.334337625]},
  {"PCM + Aislante": [118.888194053571, 257.602488910714, 184.651429357143, 134.1043315, 361.580940857143]}
]*/

const euis = [
  {"Caso 0": [316.89, 372.333977, 314.774084, 292.968429, 882.23513]},
  {"Caso 1": [255.70, 307.943718, 267.771328, 250.182654, 808.912614]},
  {"Caso 2": [299.26, 352.210774, 296.771734, 274.459061, 854.36174]},
  {"Caso 3": [298.29, 350.912271, 296.228732, 273.814774, 853.16687]},
  {"Caso 4": [264.25, 315.69084, 273.590878, 255.230192, 817.900279]},
  {"Caso 5": [266.0, 317.261348, 274.735303, 256.237873, 819.706507]},
  {"Caso 6": [255.73, 307.994647, 267.752428, 250.163732, 808.946365]},
  {"Caso 7": [264.39, 315.808594, 273.595511, 255.226644, 817.985664]},
  {"Caso 8": [266.14, 317.411347, 274.745656, 256.241935, 819.797644]},
  {"Caso 9": [267.53, 328.381443, 285.691077, 270.635765, 820.85408]},
  {"Caso 10": [275.50, 335.044225, 290.457828, 274.241133, 831.089261]},
  {"Caso 11": [277.24, 336.494192, 291.452927, 275.007271, 833.26466]},
  {"Caso 12": [267.67, 328.560545, 285.781363, 270.733569, 821.031652]},
  {"Caso 13": [275.76, 335.381207, 290.665178, 274.43007, 831.430227]},
  {"Caso 14": [277.52, 336.880169, 291.680647, 275.203392, 833.64686]},
  {"Caso 15": [315.71, 370.444728, 313.421477, 291.7842, 880.40082]}
]

//[1]: departamento [2]: restaurante [3]: aulas [4]: oficina [5]: hospital
const ahorros = [
  {"Caso 0": [0.0, 0.0, 0.0, 0.0, 0.0]},
  {"Caso 1": [61.18, 64.39, 47.00, 42.79, 73.32]},
  {"Caso 2": [17.62, 20.12, 18.00, 18.51, 27.87]},
  {"Caso 3": [18.59, 21.42, 18.55, 19.16, 29.07]},
  {"Caso 4": [52.63, 56.64, 41.18, 37.74, 64.33]},
  {"Caso 5": [50.88, 55.07, 40.04, 36.73, 62.53]},
  {"Caso 6": [61.15, 64.34, 47.02, 42.81, 73.29]},
  {"Caso 7": [52.50, 56.53, 41.18, 37.74, 64.25]},
  {"Caso 8": [50.74, 54.92, 40.03, 36.73, 62.44]},
  {"Caso 9": [49.36, 43.95, 29.08, 22.33, 61.38]},
  {"Caso 10": [41.39, 37.29, 24.32, 18.73, 51.15]},
  {"Caso 11": [39.65, 35.84, 23.32, 17.96, 48.97]},
  {"Caso 12": [49.22, 43.77, 28.99, 22.24, 61.20]},
  {"Caso 13": [41.13, 36.95, 24.11, 18.54, 50.80]},
  {"Caso 14": [39.36, 35.45, 23.09, 17.77, 48.59]},
  {"Caso 15": [1.18, 1.89, 1.35, 1.19, 1.83]}
]

const costos = [
  {"Caso 0": [780.63]},
  {"Caso 1": [1598.85]},
  {"Caso 2": [1426.23]},
  {"Caso 3": [1446.58]},
  {"Caso 4": [1547.6]},
  {"Caso 5": [1516.62]},
  {"Caso 6": [1576.5]},
  {"Caso 7": [1527.25]},
  {"Caso 8": [1496.27]},
  {"Caso 9": [951.25]},
  {"Caso 10": [902]},
  {"Caso 11": [871.02]},
  {"Caso 12": [930.9]},
  {"Caso 13": [881.65]},
  {"Caso 14": [850.67]},
  {"Caso 15": [800.98]}
]

const pcm = [
  {"Sí": [2, 2, 2, 2, 2]},
  {"No": [3, 3, 3, 3, 3]}
]

const aislante = [
  {"Foamular (XPS)": [5, 5, 5, 5, 5]},
  {"Paca FF (LDR)": [7, 7, 7, 7, 7]},
  {"Placa EPS": [11, 11, 11, 11, 11]},
  {"No": [13, 13, 13, 13, 13]}
]

const pintura = [
  {"Sí": [17, 17, 17, 17, 17]},
  {"No": [19, 19, 19, 19, 19]}
]

const tarifasResi = [
  {"1C": [1.58]},
  {"DAC": [4.80]}
]

const tarifasNoResi = [
  {"PDBT": [3.18]},
  {"GDMTH": [1.23]}
]

const useStyles = makeStyles(theme => ({
  results: {
    marginTop: 10
  },
  firstSection: {
    backgroundColor: '#f5f5f5'
  },
  placeholder: {
    backgroundColor: '#f5f5f5',
    width: "100%",
    height: "100%"
  },
  backPaper: {
    marginTop: '30px',
    marginBottom: "50px"
  },
  electricBack: {
    backgroundColor: "#004783",
    width: "100%",
    height: "100%"
  },
  electricR: {
    color: "white"
  },
  heatBack: {
    backgroundColor: "#FF0000",
    width: "100%",
    height: "100%"
  },
  heatR: {
    color: "white"
  },
  helpImg: {
    width: "116px",
    height: "60px"
  }
}));



const Edificacion = ( {edif} ) => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(null);
  const [selectedAux, setSelectedAux] = React.useState(null);
  const [selectedBase, setSelectedBase] = React.useState(null);
  const [selectedFactor, setSelectedFactor] = React.useState(null);
  const [selectedTech, setSelectedTech] = React.useState(null);
  const [selectedPCM, setSelectedPCM] = React.useState(null);
  const [selectedAis, setSelectedAis] = React.useState(null);
  const [selectedPaint, setSelectedPaint] = React.useState(null);
  const [area, setArea] = React.useState(0);

  const [resultBase, setResultBase] = React.useState('');
  const [resultProy, setResultProy] = React.useState('');

  const [geiB, setGeiB] = React.useState('');
  const [geiP, setGeiP] = React.useState('');
  
  const [ahorro, setAhorro] = React.useState(0);
  const [insight, setInsight] = React.useState(null);
  const [tree, setTree] = React.useState(null);

  const [costoSin, setCostoSin] = React.useState(0);
  const [costoCon, setCostoCon] = React.useState(0);
  const [tarifa, setTarifa] = React.useState(0);
  const [roi, setRoi] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation();

  //const tec = t('tec');
  const tipoEdif = t('tipo edif')
  const ais = t('ais');
  const pintRef = t('pint ref');
  const are = t('area te');
  const meas = t('khora');
  const meas2 = t('taño');
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOption = (optionValue) => {
    setSelected(optionValue)
  }

  const handleArea = (areaValue) =>{
    setArea(areaValue);
  }

  const handleEdifType = (edifValue) =>{
    if (edifValue !== ''){
      let arr = edifValue.split(",");
      setSelectedAux(parseInt(arr[0]));
      setSelectedBase(parseFloat(arr[1]));
      setSelectedFactor(parseFloat(arr[2]));
    }
  }

  const handleTarifa = (edifValue) =>{
    if (edifValue !== ''){
      setTarifa(edifValue);
    }
  }

  /*const handleTech = (optionsArray) =>{
    let arr = optionsArray.split(",");
    setSelectedTech(arr[selectedAux]);
  }*/

  const handlePCM = (optionsArray) => {
    let arr = optionsArray.split(",");
    setSelectedPCM(arr[selectedAux]);
  }

  const handleAis = (optionsArray) => {
    let arr = optionsArray.split(",");
    setSelectedAis(arr[selectedAux]);
  }

  const handlePaint = (optionsArray) => {
    let arr = optionsArray.split(",");
    setSelectedPaint(arr[selectedAux]);
  }

  useEffect( () =>{
    const calculateResults = () => {
      let calcBase = area * selectedBase;
      setResultBase(parseFloat(calcBase.toFixed(2)));
      let calcProy = area * selectedTech;
      setResultProy(parseFloat(calcProy.toFixed(2)));
      let saving = 100 - (calcProy*100/calcBase);
      setAhorro(parseFloat(saving.toFixed(2))); 
      let calcuIns = (calcBase - calcProy) / 4116.4;
      setInsight(parseFloat(Math.round(calcuIns)));
      let calcuTree = (calcBase - calcProy)*3.64/10000;
      setTree(parseFloat(Math.round(calcuTree)));
    }

    let caseNum = selectedAis * selectedPCM * selectedPaint;
    let aux;
    let aux2 = area / area;

    switch (caseNum) {
      case 741:
        setSelectedTech(euis[0]['Caso 0'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[0]['Caso 0'] * aux2);
        aux = ahorros[0]['Caso 0'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 170:
        setSelectedTech(euis[1]['Caso 1'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[1]['Caso 1'] * aux2);
        aux = ahorros[1]['Caso 1'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 494:
        setSelectedTech(euis[2]['Caso 2'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[2]['Caso 2'] * aux2);
        aux = ahorros[2]['Caso 2'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 442:
        setSelectedTech(euis[3]['Caso 3'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[3]['Caso 3'] * aux2);
        aux = ahorros[3]['Caso 3'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 238:
        setSelectedTech(euis[4]['Caso 4'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[4]['Caso 4'] * aux2);
        aux = ahorros[4]['Caso 4'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 374:
        setSelectedTech(euis[5]['Caso 5'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[5]['Caso 5'] * aux2);
        aux = ahorros[5]['Caso 5'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 190:
        setSelectedTech(euis[6]['Caso 6'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[6]['Caso 6'] * aux2);
        aux = ahorros[6]['Caso 6'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 266:
        setSelectedTech(euis[7]['Caso 7'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[7]['Caso 7'] * aux2);
        aux = ahorros[7]['Caso 7'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 418:
        setSelectedTech(euis[8]['Caso 8'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[8]['Caso 8'] * aux2);
        aux = ahorros[8]['Caso 8'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 255:
        setSelectedTech(euis[9]['Caso 9'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[9]['Caso 9'] * aux2);
        aux = ahorros[9]['Caso 9'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 357:
        setSelectedTech(euis[10]['Caso 10'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[10]['Caso 10'] * aux2);
        aux = ahorros[10]['Caso 10'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 561:
        setSelectedTech(euis[11]['Caso 11'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[11]['Caso 11'] * aux2);
        aux = ahorros[11]['Caso 11'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 285:
        setSelectedTech(euis[12]['Caso 12'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[12]['Caso 12'] * aux2);
        aux = ahorros[12]['Caso 12'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 399:
        setSelectedTech(euis[13]['Caso 13'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[13]['Caso 13'] * aux2);
        aux = ahorros[13]['Caso 13'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 627:
        setSelectedTech(euis[14]['Caso 14'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[14]['Caso 14'] * aux2);
        aux = ahorros[14]['Caso 14'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      case 663:
        setSelectedTech(euis[15]['Caso 15'][selectedAux]);
        setCostoSin(costos[0]['Caso 0'] * aux2);
        setCostoCon(costos[15]['Caso 15'] * aux2);
        aux = ahorros[15]['Caso 15'][selectedAux];
        setRoi((Math.log(((costoCon - costoSin) * (0.10 - 0.05)) / (aux * tarifa) + 1) / Math.log((1 + 0.10) / (1 + 0.05))) * aux2);
        break;
      default:
        break;
    }

    calculateResults();
  }, [selectedBase, selectedTech, selectedAis, selectedPCM, selectedPaint, selectedAux, area, costoSin, costoCon, tarifa]);

  useEffect( () =>{
    const calculateGei = () => {
      let calcGeiB = selectedFactor * resultBase;
      setGeiB(parseFloat(calcGeiB.toFixed(2)));
      let calcGeiP = selectedFactor * resultProy;
      setGeiP(parseFloat(calcGeiP.toFixed(2)));
    }
    calculateGei();
  }, [resultBase, resultProy, selectedFactor]);

  

  function reset() {
    setSelected(null);
    setArea(0);
    setSelectedBase('');

    setResultBase('');
    setResultProy('');

    setGeiB('');
    setGeiP('');

    setAhorro(0);
    setInsight(null);
    setTree(null);
  }
  
  return(
    <div>
      <Paper elevation={1} className={classes.backPaper}>
        <Box p={2}>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" className={classes.header}>
                {t('att')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={reset}>{t('limp')}</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <TestSelect options={edif} label={tipoEdif} selected={selected} onSelectedChange={handleOption}/>
                <DisplayEdif selectedEdif={selected} onEdifChange={handleEdifType} resi={residencial} noresi={noresidencial}/>
                <DisplayTarifa selectedEdif={selected} onTarifaChange={handleTarifa} resi={tarifasResi} noresi={tarifasNoResi} />
                {/*<DisplayCondition options={tecnologias} label={tec} param={selectedBase} onChange={handleTech}/>*/}
                <DisplayCondition options={pcm} label="PCM" param={selectedBase} onChange={handlePCM} info="pcm" />
                <DisplayCondition options={aislante} label={ais} param={selectedBase} onChange={handleAis} info="aislante" />
                <DisplayCondition options={pintura} label={pintRef} param={selectedBase} onChange={handlePaint} info="pintura" />
                <AreaInput onValueChange={handleArea} areaValue={area} idInput="input1" idHelper="input1-helper" label={are} />
              </Box>
              <Button aria-describedby={id} onClick={handleClick}>
                <InfoIcon  color="primary"/>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
              >
                <Box p={2}>
                  {t('cons ed 1')}
                  <ul>
                    <li>{t('cons ed 11')}</li>
                    <li>{t('cons ed 12')}</li>
                    <ul>
                      <li>{t('cons ed 13')}</li>
                        <ul>
                          <li>{t('cons ed 14')}</li>
                          <li>{t('cons ed 15')}</li>
                          <li>{t('cons ed 16')}</li>
                          <li>{t('cons ed 17')}</li>
                        </ul>
                      <li>{t('cons ed 18')}</li>
                      <li>{t('cons ed 19')}</li>
                    </ul>
                    <li>{t('cons ed 20')}</li>
                  </ul>
                </Box>
              </Popover>
              <Box display="flex" fontSize={16}>
                {t('calc mty mex')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ceg')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('sin tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('con tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="blue" 
                    units={meas} 
                    result={resultBase}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="blue" 
                    units={meas} 
                    result={resultProy}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ega')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('sin tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('con tec')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units={meas2} 
                    result={geiB}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units={meas2} 
                    result={geiP}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ana econ')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('costo ad sin')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {t('costo ad con')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="yellow" 
                    units="MXN/m²" 
                    result={costoSin}
                    className={classes.money}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="yellow" 
                    units="MXN/m²"
                    result={costoCon}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayAhorro ahorro={ahorro}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayRetorno roi={roi}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box textAlign="center" fontSize={20} fontStyle="medium">
                {t('res sol 3')}
              </Box>
              <DisplaySaving insight={insight} toggle={true} />
              <DisplaySaving insight={tree} toggle={false}/>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default Edificacion;