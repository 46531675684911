import React, { useState } from 'react';
import Carousel from "react-material-ui-carousel"
//import autoBind from "auto-bind"
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import '../styles/Carousel.scss';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function createData(title, name) {
  return { title, name };
}

function Banner(props) {
  if (props.newProp) console.log(props.newProp)
  const contentPosition = props.contentPosition ? props.contentPosition : "left"
  const totalItems = props.length ? props.length : 3;
  const mediaLength = totalItems - 1;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null);

  const { t } = useTranslation();

  const handleClick = (event, popoverId) => {
    setOpenedPopoverId(popoverId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  let items = [];
  const content = (
    <Grid item xs={12 / totalItems} key="content">
      <CardContent className="Content">
        <Typography className="Title">
          {props.item.Name}
        </Typography>
      </CardContent>
    </Grid>
  );


  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];

    const media = (
      <Grid item xs={12 / totalItems} key={item.Name}>
        <CardMedia
            className="Media"
            image={item.Image}
            title={item.Name}
        >
          <Button aria-describedby={item.Id} onClick={(e) => handleClick(e, item.Id)}  className="MediaTitle">
            {item.Name}
          </Button>
          
          <Popover
            id={item.Id}
            open={openedPopoverId === item.Id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
          >
            <TableContainer>
              <Table>
                {item.Info.map((row) => (
                  <TableRow>
                    <TableCell variant="head">{row.title}</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </Popover>
          
          <Typography className="MediaCaption">
            <Link href={item.Source} target="_blank" rel="noopener noreferrer" color="inherit">
              {t('fuen')}
            </Link>
          </Typography>
        </CardMedia>
      </Grid>
    );

    items.push(media);
  }

  if (contentPosition === "left") {
    items.unshift(content);
  } else if (contentPosition === "right") {
    items.push(content);
  } else if (contentPosition === "middle") {
    items.splice(items.length / 2, 0, content);
  }

  return (
    <Card raised className="Banner">
      <Grid container spacing={0} className="BannerGrid">
        {items}
      </Grid>
    </Card>
  );
}

const BannerExample = () => {
  const [autoPlay, setAutoPlay] = useState(true);
  const [timer, setTimer] = useState(1000);
  const [animation, setAnimation] = useState("fade");
  const [indicators, setIndicators] = useState(true);
  const [timeout, setTimeout] = useState(500);
  const [navButtonsAlwaysVisible, setNavButtonsAlwaysVisible] = useState(false);
  const [navButtonsAlwaysInvisible, setNavButtonsAlwaysInvisible] = useState(false);

  const { t } = useTranslation();

  const panelData = [
    createData(t('mar'), 'Sunpower'),
    createData(t('mod'), 'MAX3-370'),
    createData(t('pot'), '370 W'),
    createData('Voc', '74.7 V'),
    createData('Isc', '6.52 A'),
    createData('Vmpp', '61.8 V'),
    createData('Impp', '5.99 A')
  ];
  
  const termicData = [
    createData(t('mar'), 'Modulo Solar'),
    createData(t('mod'), 'Maxol 2.5'),
    createData(t('pot ter'), '1.8kW'),
    createData('0a', 0.775),
    createData('a1a', '3.1 W/(m²K)'),
    createData('a2a', '0.01 W/(m²K²)'),
    createData('Flow rate', '0.02 kg/s'),
    createData('Area', '2.326 m²')
  ];
  
  const pvtData = [
    createData(t('mar'), 'DualSun'),
    createData(t('mod'), 'Spring'),
    createData(t('pot el'), '280W'),
    createData(t('pot ter'), '570W/m²'),
    createData('Voc', '38.88 V'),
    createData('Isc', '9.30 A'),
    createData('Vmpp', '31.95 V'),
    createData('Impp', '8.77 A'),
    createData('Area', '1.66m²')
  ];
  
  const lcpvtData = [
    createData(t('mar'), 'Tec de Monterrey'),
    createData(t('mod'), 'PVT-110'),
    createData(t('pot el'), '1500 W'),
    createData(t('pot ter'), '250 W')
  ];

  const items = [
    {
      Name: t('tec us'),
      Caption: "Electrify your friends!",
      contentPosition: "left",
      Items: [
        {
          Id: 1,
          Name: t('pan sol'),
          Image: "https://images.unsplash.com/photo-1584276433295-4b49a252e5ee?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1942&q=80",
          Source: "https://unsplash.com/s/photos/solar-panel",
          Info: panelData
        },
        {
          Id: 2,
          Name: t('col ter2'),
          Image: "https://4.bp.blogspot.com/-Mqjst3xTtZk/WUmdHvJLTeI/AAAAAAAABi0/zNRNjeUkYrkV3qbAQhDIEaqAEkKTCrhdQCLcBGAs/s1600/reggie-bush.jpg",
          Source: "https://edyeazul.blogspot.com/p/solar-thermal-system_31.html",
          Info: termicData
        }
      ]
    },
    {
      Name: t('col hib'),
      Caption: "Say no to manual home labour!",
      contentPosition: "left",
      Items: [
        {
          Id: 3,
          Name: "PV/T",
          Image: "http://www.yougen.co.uk/i/uploads/gallery/240%20module.jpeg",
          Source: "http://www.yougen.co.uk/blog-entry/2833/Solar+PV-T+systems+-+what+are+the+pros+and+cons%273F/",
          Info: pvtData
        },
        {
          Id: 4,
          Name: "LCPV/T",
          Image: "lc.png",
          Source: "https://tec.mx/es",
          Info: lcpvtData
        }
      ]
    }
  ];

  return (
    <div style={{ marginTop: "30px", color: "#494949" }}>
      <Carousel
        className="Example"
        autoPlay={autoPlay}
        timer={timer}
        animation={animation}
        indicators={indicators}
        timeout={timeout}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
        next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
      >
        {
          items.map((item, index) => {
            return <Banner item={item} key={index} contentPosition={item.contentPosition} />
          })
        }
      </Carousel>

    </div>
  );
}

/*
class BannerExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: false,
      timer: 500,
      animation: "fade",
      indicators: true,
      timeout: 500,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false
    }

    autoBind(this);
  }

  render() {
    return (
      <div style={{ marginTop: "30px", color: "#494949" }}>
        <Carousel
          className="Example"
          autoPlay={this.state.autoPlay}
          timer={this.state.timer}
          animation={this.state.animation}
          indicators={this.state.indicators}
          timeout={this.state.timeout}
          navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
          next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        >
          {
            items.map((item, index) => {
              return <Banner item={item} key={index} contentPosition={item.contentPosition} />
            })
          }
        </Carousel>

      </div>

    );
  }
}*/

export default BannerExample;