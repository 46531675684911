import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Carousel from './Carousel';
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
//import Typography from '@material-ui/core/Typography';
//import CarouselAllies from './CarouselAllies';

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: '30px'
  },
  footer:{
    backgroundColor: "#e7ebf6",
    height: "250px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '50px'
  },
  footersection:{
    height: "150px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white"
  },
  lineas:{
    color: "white"
  },
  logo:{
    margin: "10px"
  },
  lineasBack:{
    backgroundColor: "#261149"
  },
  changeColor:{
    color: "white",
    background: "white"
  },
  list:{
    display: 'inline-block'
  },
  imgDiv:{
    imageRendering: '-webkit-optimize-contrast',
    display: 'flex',
    justifyContent: 'center',
    marginTop: "50px",
    marginBottom: "50px"
  }
}));

const HomeComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Carousel></Carousel>
      <Container>
        <div className={classes.section}></div>
        <div className={classes.imgDiv}>
          <img src="original.svg" alt="solarandbuildingslogo" height="40%" width="40%"/>
        </div>
        <Box fontSize={28} fontFamily='Tenor Sans' textAlign="center" p={4}>
          {t('intro1')}
          <strong> {t('org')} </strong>
          {t('intro2')}
        </Box>
        <div className={classes.section}></div>
        <div className={classes.section}></div>
        <Paper elevation={3} className={classes.lineasBack}>
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box fontSize={38} fontFamily='Tenor Sans' textAlign="center" p={5} className={classes.lineas}>
                  {t('lin de inv')}
                </Box>
                <Divider variant="middle" className={classes.changeColor}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.section}></div>
                <Box fontSize={28} fontFamily='Tenor Sans' textAlign="center" fontWeight="bold" color="white" marginBottom={3}>{t('pot sol')}</Box>
                <Box fontSize={22} fontFamily='Tenor Sans' textAlign="center" fontWeight="light" marginTop="10px" color="white" marginBottom={4}>{t('obj esp')}</Box>
                <Box fontSize={14} fontFamily='Tenor Sans' textAlign="center" color="white" marginBottom="40px">
                  <div>
                    {t('sol obj 1')}
                  </div>
                  <br></br>
                  <div>
                  {t('sol obj 2')}
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.section}></div>
                <Box fontSize={28} fontFamily='Tenor Sans' textAlign="center" fontWeight="bold" color="white"  marginBottom={3}>{t('edif sust')}</Box>
                <Box fontSize={22} fontFamily='Tenor Sans' textAlign="center" marginTop="10px" color="white" marginBottom={4}>{t('obj esp')}</Box>
                <Box fontSize={14} fontFamily='Tenor Sans' textAlign="center" color="white" marginBottom="40px">
                  <div>
                    {t('edif obj 1')}
                  </div>
                  <br/>
                  <div>
                    {t('edif obj 2')}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <div className={classes.footer}>
        <Box fontSize={16} fontFamily='Tenor Sans' textAlign="center" color="black" marginTop="20px" marginBottom="20px" fontWeight="bold">
          {t('pie inicio1')}<br/>
          {t('pie inicio2')}<br/>
          {t('pie inicio3')}
        </Box>
        <div className={classes.footersection}>
          <img src="https://javier.rodriguez.org.mx/itesm/2014/tecnologico-de-monterrey-blue.png" width="200px" height="52px" alt="logo" className={classes.logo}></img>
          <img src="sponsor1.jpg" width="188px" height="106px" alt="logo" className={classes.logo}></img>
          <img src="sponsor2.png" width="238px" height="52px" alt="logo" className={classes.logo}></img>
          <img src="sponsor3.jpg" width="197px" height="115px" alt="logo" className={classes.logo}></img>
        </div>
      </div>
    </div>
  );
}

export default HomeComponent; 