import React, { useState } from 'react';
import Carousel from "react-material-ui-carousel"
//import autoBind from "auto-bind"
import '../styles/Carousel.scss';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Banner(props) {
  if (props.newProp) console.log(props.newProp)
  const contentPosition = props.contentPosition ? props.contentPosition : "left";
  const totalItems = props.length ? props.length : 3;
  const mediaLength = totalItems - 1;

  let items = [];
  const content = (
    <Grid item xs={12 / totalItems} key="content">
      <CardContent className="Content">
        <Typography className="Title">
          {props.item.Name}
        </Typography>
      </CardContent>
    </Grid>
  );


  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];

    const media = (
      <Grid item xs={12 / totalItems} key={item.Name}>
        <CardMedia
          className="Media"
          image={item.Image}
          //title={item.Name}
        >
        </CardMedia>
      </Grid>
    )

    items.push(media);
  }

  if (contentPosition === "left") {
    items.unshift(content);
  } else if (contentPosition === "right") {
    items.push(content);
  } else if (contentPosition === "middle") {
    items.splice(items.length / 2, 0, content);
  }

  return (
    <Card raised className="Banner">
      <Grid container spacing={0} className="BannerGrid">
        {items}
      </Grid>
    </Card>
  );
}

const BannerExample = () => {
  const [autoPlay, setAutoPlay] = useState(true);
  const [timer, setTimer] = useState(1000);
  const [animation, setAnimation] = useState("fade");
  const [indicators, setIndicators] = useState(true);
  const [timeout, setTimeout] = useState(500);
  const [navButtonsAlwaysVisible, setNavButtonsAlwaysVisible] = useState(false);
  const [navButtonsAlwaysInvisible, setNavButtonsAlwaysInvisible] = useState(false);

  const { t } = useTranslation();

  const items = [
    {
      Name: t('pot sol'),
      //Caption: "Objetivos, alcance y metodologías",
      contentPosition: "middle",
      Items: [
        {
          Name: "Macbook Pro",
          Image: "solarA.jpg"
        },
        {
          Name: "iPhone",
          Image: "solarB.jpg"
        }
      ]
    },
    {
      Name: t('edif sust'),
      //Caption: "Objetivos, alcance y metodologías",
      contentPosition: "middle",
      Items: [
        {
          Name: "Washing Machine WX9102",
          Image: "sustA.jpg"
        },
        {
          Name: "Learus Vacuum Cleaner",
          Image: "sustB.jpg"
        }
      ]
    }
  ];

  return (
    <div style={{ marginTop: "50px", color: "#494949" }}>
      <Carousel
        className="Example"
        autoPlay={autoPlay}
        timer={timer}
        animation={animation}
        indicators={indicators}
        timeout={timeout}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
        //next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        //prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        //onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
      >
        {
          items.map((item, index) => {
            return <Banner item={item} key={index} contentPosition={item.contentPosition} />
          })
        }
      </Carousel>
    </div>
  );
}

/*
class BannerExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      timer: 1000,
      animation: "fade",
      indicators: true,
      timeout: 500,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false
    }

    autoBind(this);
  }

  toggleAutoPlay() {
    this.setState({
      autoPlay: !this.state.autoPlay
    });
  }

  toggleIndicators() {
    this.setState({
      indicators: !this.state.indicators
    });
  }

  toggleNavButtonsAlwaysVisible() {
    this.setState({
      navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
    });
  }

  toggleNavButtonsAlwaysInvisible() {
    this.setState({
      navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
    });
  }

  changeAnimation(event) {
    this.setState({
      animation: event.target.value
    });
  }

  changeTimeout(event, value) {
    this.setState({
      timeout: value
    });
  }

  render() {
    return (
      <div style={{ marginTop: "50px", color: "#494949" }}>
        <Carousel
          className="Example"
          autoPlay={this.state.autoPlay}
          timer={this.state.timer}
          animation={this.state.animation}
          indicators={this.state.indicators}
          timeout={this.state.timeout}
          navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
          //next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          //prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
          //onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
        >
          {
            items.map((item, index) => {
              return <Banner item={item} key={index} contentPosition={item.contentPosition} />
            })
          }
        </Carousel>
      </div>

    );
  }
}*/

export default BannerExample;