import React, {useState} from 'react';
import SelectLarge from './SelectLarge';
import { useTranslation } from 'react-i18next';

const DisplayEdif = ({selectedEdif, onEdifChange, resi, noresi}) => {
  const [selected, setSelected] = useState('');

  const { t } = useTranslation();

  const tipoEdifRes = t('tipo edif res');
  const tipoEdifNoRes = t('tipo edif no res');

  const handleOption = (optionValue) => {
    setSelected(optionValue);
    onEdifChange(optionValue);
  }

  function clean() {
    setSelected('');
    onEdifChange('');
  }

  if (selectedEdif === 'yes') {
    return (
      <div>
        <SelectLarge options={resi} label={tipoEdifRes} selected={selected} onSelectedChange={handleOption}/>
      </div>
    );
  }
  else if (selectedEdif === 'no') {
    return (
      <div>
        <SelectLarge options={noresi} label={tipoEdifNoRes} selected={selected} onSelectedChange={handleOption}/>
      </div>
    );
  }
  else {
    return (
      <div>
        {clean}
      </div>
    );
  }
}

export default DisplayEdif;