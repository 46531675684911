import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AreaInput from './AreaInput';
import TestSelect from './TestSelect';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import DisplayEnergy from './DisplayEnergy';
import DisplayCondition from './DisplayCondition';
import DisplaySummary from './DisplaySummary';
import DisplayCalcu from './DisplayCalcu';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import DisplayInsight from './DisplayInsight';
import { useTranslation } from 'react-i18next';
import DisplayInversion from './DisplayInversion';
import DisplayTarifa from './DisplayTarifa';
import { calculateTir } from '../utilities/tirCalculator';

const useStyles = makeStyles(theme => ({
  results: {
    marginTop: 10
  },
  firstSection: {
    backgroundColor: '#f5f5f5'
  },
placeholder: {
    backgroundColor: '#f5f5f5',
    width: "100%",
    height: "100%"
  },
  backPaper: {
    marginTop: '30px',
    marginBottom: "60px"
  },
  electricBack: {
    backgroundColor: "#004783",
    width: "100%",
    height: "100%"
  },
  electricR: {
    color: "white"
  },
  heatBack: {
    backgroundColor: "#FF0000",
    width: "100%",
    height: "100%"
  },
  heatR: {
    color: "white"
  },
  helpImg: {
    width: "116px",
    height: "60px"
  }
}));

const tipoEdif = [
  {"Residencial": ["yes"]},
  {"Industrial": ["no"]}
]

const combustibles = [
  {"Diesel": [0.00027, 1.922161]},
  {"Gas natural": [0.0002, 0.225493]},
  {"GLP": [0.0023, 1.689112]}
]

const tarifasResi = [
  {"1C": [1.58]},
  {"DAC": [4.80]}
]

const tarifasNoResi = [
  {"PDBT": [3.18]},
  {"GDMTH": [1.23]}
]


const SolarSegundo = ( {tecnologia} ) => {
  const classes = useStyles();

  const [iconName, setIconName] = React.useState("placeholder.png");
  const [fuel, setFuel] = React.useState('');
  const [selected, setSelected] = React.useState('');
  const [selectedE, setSelectedE] = React.useState('');
  const [selectedH, setSelectedH] = React.useState('');
  const [selectedT, setSelectedT] = React.useState('');
  const [tarifa, setTarifa] = React.useState('');
  const [resultE, setResultE] = React.useState('');
  const [resultH, setResultH] = React.useState('');
  const [area, setArea] = React.useState(0);
  const [edif, setEdif] = React.useState('');
  const [geiE, setGeiE] = React.useState('');
  const [geiH, setGeiH] = React.useState('');
  const [sumGei, setSumGei] = React.useState(0);

  const [insightTree, setInsightTree] = React.useState(0);
  const [insightEdif, setInsightEdif] = React.useState(0);

  const [selectedEC, setSelectedEC] = React.useState('');
  const [selectedCC, setSelectedCC] = React.useState('');

  const [ahorroComb, setAhorroComb] = React.useState(0);
  const [ahorroElec, setAhorroElec] = React.useState(0);
  const [inversion, setInversion] = React.useState(0);
  const [vpn, setVpn] = React.useState(0);
  const [tir, setTir] = React.useState(0);
  const [pbt, setPbt] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const { t } = useTranslation();

  const tec = t('tec');
  const comb = t('comb');
  const are = t('area');
  const tipoE = t('tipo edif');

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOption = (optionValue) => {
    setSelected(optionValue);
    let arr = optionValue.split(',');
    setSelectedT(arr[0]);
    setSelectedE(arr[1]);
    setSelectedH(arr[2]);
    setSelectedEC(arr[3]);
  }

  const handleEdif = (edifValue) =>{
    setEdif(edifValue);
  }

  const handleArea = (areaValue) =>{
    setArea(areaValue);
  }

  const handleFuel = (fuelValue) =>{
    console.log(fuelValue);
    let arr = fuelValue.split(',');
    setFuel(arr[0]);
    setSelectedCC(arr[1]);
  }

  const handleTarifa = (edifValue) =>{
    if (edifValue !== ''){
      setTarifa(edifValue);
    }
  }

  useEffect( () =>{
    const calculateResults = () => {
      let calcuE = selectedE * area / 1000;
      let calcIns = calcuE * 1000 / 4116.4;
      setInsightEdif(parseFloat(Math.round(calcIns)));
      setResultE(parseFloat(calcuE.toFixed(2)));
      let calcuH = selectedH * area / 1000;
      setResultH(parseFloat(calcuH.toFixed(2)));
      let calcEC = resultE * tarifa * 1000;
      setAhorroElec(parseFloat(calcEC.toFixed(2)));
      let calcCC = resultH / 0.70 * selectedCC * 1000 ;
      setAhorroComb(parseFloat(calcCC.toFixed(2)));
      let inver = area * selectedEC;
      setInversion(parseFloat(inver.toFixed(2)));
      let sum = 0;
      for (let i = 1; i <= 25; i++)
        sum += (ahorroComb + ahorroElec) / Math.pow((1 + 0.05), i);
      let v = -inversion + sum;
      setVpn(parseFloat(v.toFixed(2)));
      let t = 0;
      if (area > 0)
        t = calculateTir(inversion, ahorroComb + ahorroElec);
      else
        t = 0;
      setTir(parseFloat(t.toFixed(2)));
      if (ahorroElec + ahorroComb > 0) {
        let p = Math.log((inversion * (0.10 - 0.05)) / (ahorroElec + ahorroComb) + 1) / Math.log((1 + 0.10) / (1 + 0.05));
        setPbt(parseFloat(p.toFixed(2)));
      } 
    }
    const decideIcon = () => {
      if(selectedT === 'ct'){
        setIconName("termic.png")
      }
      else if(selectedT === 'pv'){
        setIconName("hybrid.png");
      }
      else if(selectedT === 'lc'){
        setIconName("https://www.solar-payback.com/wp-content/uploads/2017/07/parabolic-trough.jpg");
      }
      else if(selectedT === 'pf'){
        setIconName("https://www.solar-payback.com/wp-content/uploads/2017/07/flat-plate-collector.jpg");
      }
    }
    calculateResults();
    decideIcon();
  }, [selectedE, selectedH, area, selectedT, selectedCC, selectedEC, tarifa, resultE, resultH, ahorroComb, ahorroElec, inversion]);

  useEffect( () =>{
    const calculateGei = () => {
      let calcGeiE = resultE * 1000 * 0.0004536;
      setGeiE(parseFloat(calcGeiE.toFixed(2)));
      let calcGeiH = resultH * 1000 * fuel;
      setGeiH(parseFloat(calcGeiH.toFixed(2)));
      let sumG = calcGeiE + calcGeiH;
      setSumGei(parseFloat(Math.round(sumG))); 
      let calcInsTree = (sumG / 0.021) / 1000;
      setInsightTree(parseFloat(Math.round(calcInsTree)));
    }
    calculateGei();
  }, [resultE, resultH, fuel]);

  function reset() {
    setIconName('placeholder.png');
    setSelected('');
    setSelectedE('');
    setSelectedH('');
    setSelectedT('');
    setResultE('');
    setResultH('');
    setGeiE('');
    setGeiH('');
    setArea(0);
    setSumGei(0);
  }
  
  return(
    <div>
      <Paper elevation={1} className={classes.backPaper}>
        <Box p={2}>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" className={classes.header}>
                {t('cpse')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={reset}>{t('limp')}</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TestSelect options={tipoEdif} label={tipoE} selected={edif} onSelectedChange={handleEdif}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <DisplayTarifa selectedEdif={edif} onTarifaChange={handleTarifa} resi={tarifasResi} noresi={tarifasNoResi} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TestSelect options={tecnologia} label={tec} selected={selected} onSelectedChange={handleOption}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <DisplayCondition param={selectedH} onChange={handleFuel} options={combustibles} label={comb} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <AreaInput onValueChange={handleArea} areaValue={area} idInput="input2" idHelper="input2-helper" label={are} />
                </Grid>
                <Grid item xd={12} sm={12}>
                  <Button aria-describedby={id} onClick={handleClick}>
                    <InfoIcon  color="primary"/>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                  >
                    <Box p={2}>
                      <strong>{t('sup y co')}</strong>
                      <div>
                        {t('sup y co 1')}
                        <br/>{t('sup y co 2')}
                        <br/>{t('sup y co 3')}
                        <br/>{t('sup y co 4')}
                      </div>
                      <br/>
                      <strong>{t('p pv')}</strong>
                      <div>
                        {t('p pv 1')}
                        <br/>{t('p pv 2')}
                        <br/>{t('p pv 3')}
                      </div>
                      <br/>
                      <strong>{t('col ter')}</strong>
                      <div>
                        {t('col ter 1')}
                        <br/>{t('col ter 2')}
                        <br/>{t('col ter 3')}
                      </div>
                      <br/>
                      <strong>{t('pvt')}</strong>
                      <div>
                        {t('pvt 1')}				
                        <br/>{t('pvt 2')}
                        <br/>{t('pvt 3')}
                        <br/>{t('pvt 4')}
                      </div>
                          <br/>
                      <strong>{t('lcpvt')}</strong>
                      <div>
                        {t('lcpvt 1')}				
                        <br/>{t('lcpvt 2')}		
                        <br/>{t('lcpvt 3')}
                        <br/>{t('lcpvt 4')}
                      </div>		
                    </Box>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('res sol 1')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayEnergy 
                    choice="blue" 
                    units="MWh AC" 
                    result={resultE}
                  >
                  </DisplayEnergy>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayEnergy 
                    choice="red" 
                    units="MWh" 
                    result={resultH}
                  >
                  </DisplayEnergy>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('res sol 2')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units="tCO2" 
                    result={geiE}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayCalcu 
                    choice="gray" 
                    units="tCO2" 
                    result={geiH}
                  >
                  </DisplayCalcu>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box textAlign="center">
                    <Typography variant="h5">
                      {t('ana econ')}
                    </Typography>
                  </Box>
                </Grid>
                <DisplayInversion ahorroE={ahorroElec} ahorroC={ahorroComb} inversion={inversion} vpn={vpn} tir={tir} pbt={pbt} />
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">
                    {t('res sol 3')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayInsight insight={insightEdif} toggle={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisplayInsight insight={insightTree} toggle={false} mil={true}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <DisplaySummary sumG={sumGei} imgName={iconName}/>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default SolarSegundo;