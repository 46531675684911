import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CarouselT from './CarouselT';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  photos: {
    height: '120px',
    width: '120px'
  },
  footer:{
    backgroundColor: "#e7ebf6",
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '50px'
  },
}));

const ContactComponent = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return(
    <div>
      <CarouselT />
      <Container>
        <Box fontSize={25} fontFamily='Tenor Sans' textAlign="left">
          <strong>{t('cap')}</strong>
        </Box>
        <Box fontSize={20} fontFamily='Tenor Sans' textAlign="left" p={4}>
          {t('cap 1')}
          <ul>
            <li>{t('cap 2')}</li>
            <li>{t('cap 3')}</li>
            <li>{t('cap 4')}</li>
          </ul>
          {t('cap 5')}
          <ul>
            <li>{t('cap 6')}</li>
            <li>{t('cap 7')}</li>
          </ul>
          {t('cap 8')}
        </Box>
        <Box fontSize={25} fontFamily='Tenor Sans' textAlign="left">
          <strong>{t('herr')}</strong>
        </Box>
        <Box fontSize={20} fontFamily='Tenor Sans' textAlign="left" p={4}>
          <strong>Software</strong>
          <ul>
            <li>{t('soft 1')}</li>
            <li>{t('soft 2')}</li>
            <li>{t('soft 3')}</li>
            <li>{t('soft 4')}</li>
            <li>{t('soft 5')}</li>
            <li>{t('soft 6')}</li>
            <li>{t('soft 7')}</li>
            <li>{t('soft 8')}</li>
          </ul>
          <strong>{t('equip')}</strong>
          <ul>
            <li>{t('equip 1')}</li>
            <li>{t('equip 2')}</li>
          </ul>
        </Box>
        <Box fontSize={25} fontFamily='Tenor Sans' textAlign="left">
          <strong>{t('proy')}</strong>
        </Box>
        <Box fontSize={20} fontFamily='Tenor Sans' textAlign="left">
          <ul>
            <li>{t('proy 1')}</li>
            <li>{t('proy 2')}</li>
            <li>{t('proy 3')}</li>
          </ul>
        </Box>
      </Container>
    </div>
  );
}

export default ContactComponent;