import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    margin: '0px 0px 20px 0px'
  },
  pos: {
    marginBottom: 12,
  },
  start: {
    margin: '20px 0px 0px 0px'
  }
});

const ArticlesComponent = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return(
    <div>
      <Container>
        <Box display="flex" justifyContent="center" m={1} fontFamily="Tenor Sans" fontWeight="bold" fontSize={30} marginTop="30px">
          {t('pub')}
        </Box>
        <Grid container spacing={2} className={classes.start}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Scaling-up the installation of hybrid solar collectors to reduce CO2 emissions in a Mexican industrial sector from now to 2030.             
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Applied Energy (2021).
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button href="https://doi.org/10.1016/j.apenergy.2021.117202" target="_blank">DOI</Button>
              </ButtonGroup>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                An overview of sustainability of heat exchangers and solar thermal applications with nanofluids: A review.               </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Renewable and Sustainable Energy Reviews (2021).
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button href="https://doi.org/10.1016/j.rser.2021.110855" target="_blank">DOI</Button>
              </ButtonGroup>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Energetic and Economic Analyses of an LCPV/T Solar Hybrid Plant for a Sports Center Building in Mexico.
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Energies (2020).
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button href="https://doi.org/10.3390/en13215681" target="_blank">DOI</Button>
              </ButtonGroup>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Heat transfer model for thermal performance analysis of parabolic trough solar collectors using nanofluids.
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Renewable Energy (2018).
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button href="https://doi.org/10.1016/j.renene.2018.02.069" target="_blank">DOI</Button>
              </ButtonGroup>
            </CardContent>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Theoretical and experimental development of cooling load temperature difference factors to calculate cooling loads for buildings in warm climates.              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Applied thermal Engineering (2019).
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button href="https://doi.org/10.1016/j.applthermaleng.2018.12.136" target="_blank">DOI</Button>
              </ButtonGroup>
            </CardContent>
          </Card>
        </Grid>   
      </Container>
    </div>
  );
}

export default ArticlesComponent;