import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'
import DisplayStat from './DisplayStat';
import DisplayTemp from './DisplayTemp';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

/*
const dummy= [
  {hour: '10:00', temperature: 20},
  {hour: '10:00', temperature: 20},
  {hour: '10:00', temperature: 20},
  {hour: '10:00', temperature: 20},
  {hour: '10:00', temperature: 20}
]*/

const useStyles = makeStyles({
  back: {
    marginTop: "20px",
  },
});

const DisplayWeather = () => {

  const classes = useStyles();

  const [wind, setWind] = useState(0);
  const [rad, setRad] = useState(0);
  const [buffer, setBuffer] = useState(null);
  const [data, setData] = useState(null);
  const [time, setTime] = useState(null);

  const { t } = useTranslation();

  const velViento = t('vel viento');
  const radSol = t('rad sol');

  useEffect( () =>{
    const callApi = async () => {
      let currTime = (new Date()).toLocaleTimeString();
      setTime(currTime);

      const { data } = await axios.get('https://enviromental.herokuapp.com/current');

      setWind(data.sensors[0].data[0].wind_speed_last);
      setRad(data.sensors[0].data[0].solar_rad);
    };
    callApi();
    const interval = setInterval(() => callApi(), 300000)
    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect( () =>{
    const callHistoric = async () =>{
      const { data } = await axios.get('https://enviromental.herokuapp.com/historic');
      setBuffer(data.sensors[0].data);
    };
    callHistoric();
    const intervalo = setInterval(() => callHistoric(), 300000)
    return () => {
      clearInterval(intervalo);
    }
  }, []);

  useEffect( () =>{
    const dataStructure = () =>{
      if (buffer != null) {
        let arr = [];
        buffer.forEach((item) => {
          let convertedTemp = parseFloat(((item.temp_avg - 32) * (5/9)).toFixed(2));
          let miliseconds = item.ts * 1000;
          let time = new Date(miliseconds);
          let minutes = time.getMinutes();
          if(minutes === 0){
              minutes = '00'
          }
          let formattedTime = time.getHours() + ':' + minutes;
          arr.push({hour: formattedTime, temperature: convertedTemp});
        });
      console.log(arr);
      setData(arr);
      }
    };
    dataStructure();
  }, [buffer]);

  return(
    <div>
      <Paper elevation ={1} className={classes.back}>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box fontSize={30}>
                {t('estacion 1')}
              </Box>
              <Box>
                {t('estacion 2')} {time}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" justifyContent="space-between">
                <DisplayStat title={velViento} img="https://media.giphy.com/media/ygx8X4iqGFVwRDOCn7/giphy.gif" data={wind} units="mph"/>
                <DisplayStat title={radSol} img="https://media.giphy.com/media/QTBptzxDcMWsG9OeFw/giphy.gif" data={rad} units="w/m²"/>
                <DisplayTemp data={data}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default DisplayWeather;