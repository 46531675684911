import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import MemberView from './MemberView';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ContactCard from './ContactCard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footer:{
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '200px'
  },
}));

const ContactComponent = () => {
  const classes = useStyles();

  return(
    <div className={classes.footer}>
      <ContactCard />
    </div>
  );
}

export default ContactComponent;