import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectLarge from './SelectLarge';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    minWidth: 550,
  },
});

function createData(sector, tipoTarifa, costo) {
  return { sector, tipoTarifa, costo };
}

const DisplayTarifa = ({selectedEdif, onTarifaChange, resi, noresi}) => {
  const classes = useStyles();

  const [selected, setSelected] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tipoEdifRes = t('tarifa res');
  const tipoEdifNoRes = t('tarifa no res');

  const handleOption = (optionValue) => {
    setSelected(optionValue);
    onTarifaChange(optionValue);
  }

  function clean() {
    setSelected('');
    onTarifaChange('');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rows = [
    createData(t('res'), 'IC', '$1.58 MXN/kWh'),
    createData(t('res'), 'DAC', '$4.80 MXN/kWh'),
    createData(t('com'), 'PDBT', '$3.18 MXN/kWh'),
    createData(t('ind'), 'GDMTH', '$1.23 MXN/kWh'),
  ];

  if (selectedEdif === 'yes') {
    return (
      <div>
        <SelectLarge options={resi} label={tipoEdifRes} selected={selected} onSelectedChange={handleOption}/>
        <Button aria-describedby={id} onClick={handleClick}>
            <InfoIcon  color="primary"/>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
          }}
              transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
          }}
        >
          <TableContainer component={Paper} p={2}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Sector</TableCell>
                  <TableCell align="left">{t('tipo tar')}</TableCell>
                  <TableCell align="left">{t('costo prom')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.sector}>
                    <TableCell component="th" scope="row">
                      {row.sector}
                    </TableCell>
                    <TableCell align="left">{row.tipoTarifa}</TableCell>
                    <TableCell align="left">{row.costo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>        
      </div>
    );
  }
  else if (selectedEdif === 'no') {
    return (
      <div>
        <SelectLarge options={noresi} label={tipoEdifNoRes} selected={selected} onSelectedChange={handleOption}/>
        <Button aria-describedby={id} onClick={handleClick}>
          <InfoIcon  color="primary"/>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
          }}
              transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
          }}
        >
          <TableContainer component={Paper} p={2}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Sector</TableCell>
                  <TableCell align="left">{t('tipo tar')}</TableCell>
                  <TableCell align="left">{t('costo prom')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.sector}>
                    <TableCell component="th" scope="row">
                      {row.sector}
                    </TableCell>
                    <TableCell align="left">{row.tipoTarifa}</TableCell>
                    <TableCell align="left">{row.costo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>
      </div>
    );
  }
  else {
    return (
      <div>
        {clean}
      </div>
    );
  }
}

export default DisplayTarifa;