import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@material-ui/core';
import pdf1 from '../docs/Parte 1 Caso de Estudio.pdf'
import pdf2 from '../docs/Parte 2 Edificios.pdf'
import pdf3 from '../docs/Parte 3 Solar.pdf'
import pdf4 from '../docs/energia_solar_termica.pdf'
import pdf5 from '../docs/tendencias_energia_edificios.pdf'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

/*const useStyles = makeStyles(theme => ({
  photos: {
    height: '120px',
    width: '120px'
  },
  footer:{
    backgroundColor: "#e7ebf6",
    height: "300px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '50px'
  },
})); */

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    margin: '0px 0px 20px 0px'
  },
  pos: {
    marginBottom: 12,
  },
  start: {
    margin: '20px 0px 0px 0px'
  },
});

const NewsComponent = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return(
    <div>
      <Container>
        <Box display="flex" justifyContent="center" m={1} fontFamily="Tenor Sans" fontWeight="bold" fontSize={30} marginTop="30px">
          {t('not')}
        </Box>
        <Grid container spacing={2} className={classes.start}>
          <Card className={classes.root}>
            <Grid container>
              <Grid sm={4}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {t('titulo noticia 1')}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {t('fecha noticia 1')}
                  </Typography>
                  <Typography variant="body2" component="p">
                    
                  </Typography>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button><PictureAsPdfIcon />&nbsp;<a href={pdf1} target="_blank">{t('doc1 noticia 1')}</a></Button>
                    <Button><PictureAsPdfIcon />&nbsp;<a href={pdf2} target="_blank">{t('doc2 noticia 1')}</a></Button>
                    <Button><PictureAsPdfIcon />&nbsp;<a href={pdf3} target="_blank">{t('doc3 noticia 1')}</a></Button>
                  </ButtonGroup>
                </CardContent>
              </Grid>
              <Grid sm={8}>
                <div>
                  <img src="/taller_tecsb.jpg" width="100%" />
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card className={classes.root}>
            <Grid container>
              <Grid sm={8}>
                <div>
                  <img src="/taller_eie.jpg" width="100%" />
                </div>
              </Grid>
              <Grid sm={4}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {t('titulo noticia 2')}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {t('fecha noticia 2')}
                  </Typography>
                  <Typography variant="body2" component="p">
                    
                  </Typography>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button><PictureAsPdfIcon />&nbsp;<a href={pdf4} target="_blank">{t('doc1 noticia 2')}</a></Button>
                    <Button><PictureAsPdfIcon />&nbsp;<a href={pdf5} target="_blank">{t('doc2 noticia 2')}</a></Button>
                  </ButtonGroup>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>   
      </Container>
    </div>
  );
}

export default NewsComponent;