import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

/*const iconList = [
    { name: "panel.png"},
    { name: "hybrid.png"},
    { name: "termic.png"}
]*/

const useStyles = makeStyles(theme => ({
  //Estas son del panel.png
  helpImg: {
    width: "50%",
    height: "50%"
  },
  moneyBack: {
    backgroundColor: "#4dbd4b",
    width: "100%",
    height: "80px"
  },
  placeholder:{
    backgroundColor: "#e0e0e0",
    width: "100%",
    height: "80px"
  }
}));

const DisplayInversion = ({ ahorroE, ahorroC, inversion, vpn, tir, pbt }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Box textAlign="center">
          <Typography variant="h6">
            {t('inv ini')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box textAlign="center">
          <Typography variant="h6">
            {t('val pres neto')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {inversion > 0 ?
          <Paper className={classes.moneyBack}>
            <Box display="flex" justifyContent="space-around" alignItems="center" p={2} fontSize={18} color="white">
              <img src="money.png" width="60px" height="50px" alt="icon"/>
              ${inversion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Box>
          </Paper>
        :
          <Paper className={classes.placeholder}>
          </Paper>
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        {vpn > 0 ? 
          <Paper className={classes.moneyBack}>
            <Box display="flex" justifyContent="space-around" alignItems="center" p={2} fontSize={18} color="white">
              <img src="money.png" width="60px" height="50px" alt="icon"/>
              ${vpn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Box>
          </Paper>
        :
          <Paper className={classes.placeholder}>
          </Paper>
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper>
          <Box display="flex" justifyContent="center" textAlign="center" fontSize={20} fontWeight="bold" p={2}>
            {t('tir')}
          </Box>
          <Box display="flex" justifyContent="center" textAlign="center" fontSize={16} p={2}>
            {tir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper>
          <Box display="flex" justifyContent="center" textAlign="center" fontSize={20} fontWeight="bold" p={2}>
            {t('tiempo ret inv')}
          </Box>
          <Box display="flex" justifyContent="center" textAlign="center" fontSize={16} p={2}>
            {pbt} años
          </Box>
        </Paper>
      </Grid>
    </>
  );
    {/*<div>
      <Paper>
        <Grid container spacing={1} >
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center" p={1}>
              <Typography variant="h6">
                {t('eval fin')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center" marginTop="10px">
              <Typography variant="subtitle1">
                {t('inv ini')}: ${inversion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                {t('val pres neto')}: ${vpn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                {t('tir')}: {tir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center" marginLeft="15px" marginRight="15px">
              <Typography variant="subtitle1">
                PBT: {pbt} años
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>*/}
}

export default DisplayInversion;