export const calculateTir = (inversion, flujo) => {
  let aux1 = flujo * 25 / inversion;
  let flujoTotal = flujo * 25;

  let aux2 = 0;
  for (let i = 1; i <= 25; i++)
    aux2 += i * flujo;
  let aux3 = flujoTotal / aux2;
  let r1 = Math.pow(aux1, aux3) - 1;

  let aux4 = 0;
  for (let i = 1; i <= 25; i++)
    aux4 += flujo / i;
  let aux5 = aux4 / flujoTotal;
  let r2 = Math.pow(aux1, aux5) - 1;

  let ri = Math.ceil(r1 * 100);
  let rj = ri + 1;
  let rx = Math.floor(r2 * 100);
  while (rj <= rx) {
    console.log(ri);
    let sum1 = 0;
    for (let i = 1; i <= 25; i++)
      sum1 += flujo / Math.pow((1 + ri / 100), i);
    
    let sum2 = 0;
    for (let i = 1; i <= 25; i++)
      sum2 += flujo / Math.pow((1 + rj / 100), i);
    
    let van1 = -inversion + sum1;
    let van2 = -inversion + sum2;

    console.log(van1 + " " + van2);

    if (van1 > 0 && van2 < 0)
      return ri + (van1 / (van1 + -van2));

    ri += 1;
    rj += 1;
  }

  return 0;
}