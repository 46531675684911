import React, {useState} from 'react';
import TestSelect from './TestSelect';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next';

const DisplayCondition = ({param, onChange, label, options, info=""}) => {
  const [selected, setSelected] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOption = (optionValue) => {
    setSelected(optionValue);
    onChange(optionValue);
  }
  
  function reset() {
    setSelected('');
    onChange('');
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  if(param > 0){
    return(
      <div>
        <TestSelect options={options} label={label} selected={selected} onSelectedChange={handleOption} />
        {info === "pcm" ? 
          <>
            <Button aria-describedby={id} onClick={handleClick}>
              <InfoIcon  color="primary"/>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
              }}
                  transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
              }}
            >
              <Box p={2}>
                {t('pcm info 1')} <br/>
                {t('pcm info 2')} <br/>
                {t('pcm info 3')} <br/><br/>
                {t('pcm info 4')} <br/>
                {t('pcm info 5')} <br/>
                {t('pcm info 6')} <br/>
                {t('pcm info 7')} <br/><br/>
                {t('pcm info 8')} <br/>
                {t('pcm info 9')}
              </Box>
            </Popover>
          </>
        :
        info === "aislante" ? 
          <>
            <Button aria-describedby={id} onClick={handleClick}>
              <InfoIcon  color="primary"/>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
              }}
                  transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
              }}
            >
              <Box p={2}>
                {t('aislante info 1')} <br/>
                {t('aislante info 2')} <br/>
                {t('aislante info 3')} <br/>
                {t('aislante info 4')} <br/>
                {t('aislante info 5')}
                <ul>
                  <li>
                    {t('aislante info 6')} <br/>
                    {t('aislante info 7')} <br/>
                    {t('aislante info 8')} <br/>
                    {t('aislante info 8.1')}
                  </li>
                  <li>
                    {t('aislante info 9')} <br/>
                    {t('aislante info 10')} <br/>
                    {t('aislante info 11')} <br/>
                    {t('aislante info 11.1')}
                  </li>
                  <li>
                    {t('aislante info 12')} <br/>
                    {t('aislante info 13')} <br/>
                    {t('aislante info 14')} <br/>
                    {t('aislante info 14.1')}
                  </li>
                </ul>
              </Box>
            </Popover>
          </>
        :
        info === "pintura" ?
          <>
            <Button aria-describedby={id} onClick={handleClick}>
              <InfoIcon  color="primary"/>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
              }}
                  transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
              }}
            >
              <Box p={2}>
                {t('pintura info 1')} <br/>
                {t('pintura info 2')} <br/>
                {t('pintura info 3')} <br/>
                {t('pintura info 4')} <br/>
                {t('pintura info 5')} <br/>
                {t('pintura info 6')} <br/>
                {t('pintura info 7')}
              </Box>
            </Popover>
          </>
        :
        info === "ventanas" ?
            <>
              <Button aria-describedby={id} onClick={handleClick}>
                <InfoIcon  color="primary"/>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
              >
                <Box p={2}>
                  {t('ventana 1')} <br/>
                  {t('ventana 2')} <br/>
                  {t('ventana 3')} <br/>
                  {t('ventana 4')}
                  <ul>
                    <li>
                      {t('ventana 5')} <br/>
                      {t('ventana 6')} <br/>
                      {t('ventana 7')} <br/>
                      {t('ventana 8')} 
                    </li>
                    <li>
                      {t('ventana 9')} <br/>
                      {t('ventana 10')} <br/>
                      {t('ventana 11')}
                    </li>
                    <li>
                      {t('ventana 12')} <br/>
                      {t('ventana 13')} <br/>
                      {t('ventana 14')}
                    </li>
                    <li>
                      {t('ventana 15')} <br/>
                      {t('ventana 16')} <br/>
                      {t('ventana 17')} <br/>
                      {t('ventana 18')}
                    </li>
                  </ul>
                    {t('ventana 19')} <br/>
                    {t('ventana 20')}
                </Box>
              </Popover>
            </>
        :
          <></>
        }
      </div>
    );
  }
  else{
    return(
      <div>
        {reset}
      </div>
    );
  }
}

export default DisplayCondition;